<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="warning"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row"
      />
      <a href="/#/setUp/systemSetup"
         style="width:60px;height:40px;line-height: 40px;margin-left: 5px;color:rgb(59 82 224)">{{ $t('更新') }}</a>
    </div>
  </el-card>
  <div class="system-user-container">
    <el-card shadow="hover">
      <h3 class="title">{{ $t('队列设置') }}</h3>
      <div class="system-user-search">
        <div style="padding-bottom:10px;">
          <div>
            <el-button size="small" type="primary" @click="onOpenAddMenu">{{ $t('添加') }}</el-button>
          </div>
        </div>
        <div>
          <el-input size="small" v-model="keyword" :placeholder="$t('队列名称或号码')" prefix-icon="el-icon-search"
                    style="max-width: 180px;margin-right:3px;" @keyup.enter="handleSearch"></el-input>
          <el-button size="small" type="primary" @click="handleSearch">{{ $t('查询') }}</el-button>
        </div>
      </div>
      <el-table :data="queueData" stripe style="width: 100%;margin-top:15px;" class="table" id="out-table"
                @sort-change="changeTableSort">
        <el-table-column prop="name" :label="$t('名称')" show-overflow-tooltip></el-table-column>
        <el-table-column prop="number" :label="$t('号码')" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('状态')">
          <template #default="scope">
            <el-tag size="medium" type="success" v-if="scope.row.status == 1">{{ $t('启用') }}</el-tag>
            <el-tag size="medium" type="info" v-if="scope.row.status == 0">{{ $t('禁用') }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" :label="$t('创建时间')" show-overflow-tooltip
                         sortable="custom" width="180"></el-table-column>
        <el-table-column prop="path" :label="$t('操作')" width="100" fixed="right">
          <template #default="scope">
            <el-button size="mini" type="text" @click="onOpenEditMenu(scope.row)">{{ $t('编辑') }}</el-button>
            <el-button size="mini" type="text" @click="onRowDel(scope.row)"
                       class="u-delete" style="margin-left: 0px !important;">{{ $t('删除') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                     @current-change="onHandleCurrentChange" class="mt15" :pager-count="5" :page-count="pageCount"
                     :current-page="pageNum" background :page-size="pageSize" layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
      <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                     :page-size="pageSize" small layout=" prev, pager, next" :page-count="pageCount"
                     :current-page="pageNum"
                     :total="total" class="mt15"></el-pagination>
    </el-card>
    <AddMenu ref="addMenuRef" @print="print"/>
    <EditMenu ref="editMenuRef" @print="print"/>
  </div>
</template>
<script>
import {
  ref,
  reactive,
  toRefs,
  onMounted
} from 'vue';
import {useI18n} from "vue-i18n";
import AddMenu from './queueCom/addMenu';
import EditMenu from './queueCom/editMenu';
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import {
  getQueue,
  deleteQueue
} from '@/http/group.api.js';
import router from "@/router";

function blackList(state) {
  let params = {
    keyword: state.keyword,
    sort_type: state.sortType,
    sort_by: state.sortBy,
    page: state.pageNum
  }
  getQueue(params).then(res => {
    state.queueData = res.data.list;
    state.pageCount = res.data.pages;
    state.pageSize  = res.data.length;
    state.total     = res.data.rows;
    state.sysTitle  = res.tag;
    if (res.tag) {
      router.push('/setUp/systemSetup')
    }
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'queue',
  components: {
    AddMenu,
    EditMenu
  },
  setup() {
    const {t}   = useI18n()
    const state = reactive({
      sysTitle: '',
      queueData: [],
      pageSize: 5,   //每页数据条数
      pageNum: 1,     //页码
      total: 0,      //总数据条数
      pageCount: 0,  //总页数
      clientWidth: '',
      keyword: '',
      sortBy: '',
      sortType: ''
    });
    onMounted(() => {
      blackList(state);
      state.clientWidth = window.innerWidth;
    })
    const print                 = () => {
      blackList(state);
    }
    const addMenuRef            = ref();
    // 打开新增菜单弹窗
    const onOpenAddMenu         = () => {
      addMenuRef.value.openDialog();
    };
    const editMenuRef           = ref();
    // 打开编辑菜单弹窗
    const onOpenEditMenu        = (row) => {
      editMenuRef.value.openDialog(row);
    };
    // 当前行删除
    const onRowDel              = (row) => {
      ElMessageBox.confirm(t("你确定要删除该队列吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            deleteQueue(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                blackList(state)
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    };
    const handleSearch          = () => {
      blackList(state);
    }
    const changeTableSort       = (column) => {
      state.sortBy   = column.prop;
      state.sortType = column.order;
      blackList(state)
    }
    // 分页改变
    const onHandleSizeChange    = (val) => {
      state.pageSize = val;
      blackList(state)
    };
    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.pageNum = val;
      blackList(state)
    };
    return {
      addMenuRef,
      onOpenAddMenu,
      ...toRefs(state),
      onRowDel,
      handleSearch,
      changeTableSort,
      onHandleSizeChange,
      onHandleCurrentChange,
      editMenuRef,
      onOpenEditMenu,
      print
    }
  }
}
</script>
<style lang="scss" scoped>
.n-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.n-flex h3 {
  font-weight: 500;
  font-size: 1.2rem;
}

.table {
  :deep(.hidden-columns) {
    visibility: visible !important;
    position: relative !important;
    z-index: 1 !important;
  }
}

.system-user-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mt15 {
  text-align: center;
  margin-top: 15px;
}

.title {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 15px;
}
</style>
