<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增队列')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane :label="$t('基本信息')" name="first">
          <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="130px" label-position="top"
                   ref="validateFrom" :rules="rules">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('名称')" prop="name">
                  <el-input v-model="ruleForm.name" :placeholder="$t('名称')" maxlength="16" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('状态')" prop="status">
                  <el-switch v-model="ruleForm.status" active-value="1" inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('号码')" prop="number">
                  <el-input v-model="ruleForm.number" :placeholder="$t('号码')" maxlength="8"
                            onkeyup="value=value.replace(/[^\d]/g,'')" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('排队人数')" prop="max_wait_num">
                  <el-input-number v-model="ruleForm.max_wait_num" :min="1" :max="100" maxlength="3"
                                   controls-position="right"/>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('提示音间隔')" prop="wait_interval">
                  <el-select v-model="ruleForm.wait_interval" :placeholder="$t('提示音间隔s')" filterable allow-create>
                    <el-option label="0" value="0">0</el-option>
                    <el-option label="10" value="10">10</el-option>
                    <el-option label="20" value="20">20</el-option>
                    <el-option label="30" value="30">30</el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('提示音状态')">
                  <el-switch v-model="ruleForm.wait_media_switch" active-value="1" inactive-value="0"></el-switch>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('失败提示音')" prop="queue_fail_media">
                  <el-select v-model="ruleForm.queue_fail_media" filterable clearable :placeholder="$t('提示音')">
                    <el-option v-for="(item,index) in media" :key="index" :label="item.name" :value="item.id"
                               @click="playMusic(index)"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('试听')">
                  <div class="footer">
                    <audio :src="ruleForm.musicUrl" controls></audio>
                  </div>
                </el-form-item>
              </el-col>
              <!--              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
              <!--                <el-form-item :label="$t('等待提示音')" prop="wait_media">-->
              <!--                  <el-select v-model="ruleForm.wait_media" filterable clearable :placeholder="$t('提示音')">-->
              <!--                    <el-option v-for="(item1,index) in media" :key="index" :label="item1.name" :value="item1.id"-->
              <!--                               @click="plaMusic(index)"></el-option>-->
              <!--                  </el-select>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <!--              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
              <!--                <el-form-item :label="$t('试听')">-->
              <!--                  <div class="footer">-->
              <!--                    <audio :src="ruleForm.musicUrl1" controls></audio>-->
              <!--                  </div>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->

              <!--              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
              <!--                <el-form-item :label="$t('提示音播放顺序')" prop="play_order">-->
              <!--                  <el-radio-group v-model="ruleForm.play_order">-->
              <!--                    <el-radio label="0">{{ $t('前') }}</el-radio>-->
              <!--                    <el-radio label="1">{{ $t('后') }}</el-radio>-->
              <!--                  </el-radio-group>-->
              <!--                </el-form-item>-->
              <!--              </el-col>-->
              <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item :label="$t('等待超时时间')" prop="wait_timeout">
                  <el-input-number v-model="ruleForm.wait_timeout" maxlength="3" :min="0" :max="999"/>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <!-- <el-tab-pane label="队列成员" name="second">
          <el-form :model="ruleForm" size="small" label-width="90px">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
                <p class="member">分机成员</p>
                <el-transfer v-model="value" :props="{ key: 'value', label: 'desc',}" :data="data" filterable
                  :titles="['可用分机', '成员分机']" :button-texts="['移除', '添加']" filter-placeholder="搜索分机">
                </el-transfer>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane> -->
      </el-tabs>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  addQueue
} from '@/http/group.api.js';
import {
  simpleMedia,
} from '@/http/media.api.js';
import {
  ElNotification
} from 'element-plus';

export default {
  name: 'systemAddMenu',
  emits: ['print'],
  setup(props, context) {
    let {t, locale}    = useI18n()
    locale.value       = localStorage.getItem('locale');
    const state        = reactive({
      isShowDialog: false,
      ruleForm: {
        name: '',
        number: '',
        max_wait_num: '',
        wait_media_switch: '0',
        wait_interval: '0',
        queue_fail_media: '',
        wait_media: '',
        status: 0,
        play_order: '0',
        wait_timeout: '0',
        musicUrl: '',
        musicUrl1: '',
      },
      activeName: 'first',
      tabPosition: 'left',
      media: [],
    });
    const validateFrom = ref();
    //校验规则
    const rules        = computed(() => {
      const rule = {
        name: [{
          required: true,
          message: t("请输入名称"),
          trigger: "blur"
        }],
        number: [{
          required: true,
          message: t("请输入号码"),
          trigger: "blur"
        },
          {
            pattern: /^[0-9]\d*$/,
            message: t('队列号码只允许添加数字'),
            trigger: 'blur',
          }],
        max_wait_num: [{
          required: true,
          message: t("请输入最大排队人数"),
          trigger: "blur"
        }],
        wait_interval: [{
          required: true,
          message: t("请输入等待间隔"),
          trigger: "change"
        }],
        queue_fail_media: [{
          required: true,
          message: t("请选择失败音"),
          trigger: "change"
        }],
        wait_media: [{
          required: true,
          message: t("请选择等待音"),
          trigger: "change"
        }],
        play_order: [{
          required: true,
          message: t("请选择播放顺序"),
          trigger: "change"
        }],
      };
      return rule;
    })
    // 打开弹窗
    const openDialog   = () => {
      state.isShowDialog = true;
      simpleMedia().then(res => {
        state.media = res.data;
      }).catch(err => {
        console.log(err, 'err');
      })
    };
    // 关闭弹窗
    const closeDialog  = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel     = () => {
      closeDialog();
      initForm();
    };
    // 新增
    const onSubmit     = () => {
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            name: state.ruleForm.name,
            number: state.ruleForm.number,
            max_wait_num: state.ruleForm.max_wait_num,
            wait_media_switch: state.ruleForm.wait_media_switch,
            wait_interval: state.ruleForm.wait_interval,
            queue_fail_media: state.ruleForm.queue_fail_media,
            wait_media: state.ruleForm.wait_media,
            status: state.ruleForm.status,
            play_order: state.ruleForm.play_order,
            wait_timeout: state.ruleForm.wait_timeout,
          };
          addQueue(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      })
    };
    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm     = () => {
      state.ruleForm.name              = '';
      state.ruleForm.number            = '';
      state.ruleForm.max_wait_num      = '';
      state.ruleForm.wait_interval     = '';
      state.ruleForm.queue_fail_media  = '';
      state.ruleForm.wait_media        = '';
      state.ruleForm.status            = 0;
      state.ruleForm.play_order        = '0';
      state.ruleForm.wait_media_switch = '0';
      state.ruleForm.wait_timeout      = 0;
      state.ruleForm.musicUrl          = '';
      state.ruleForm.musicUrl1         = '';
    };
    //失败提示音
    const playMusic    = (res) => {
      state.ruleForm.musicUrl = state.media[res].url;
    }
    //等待提示音
    const plaMusic     = (res) => {
      state.ruleForm.musicUrl1 = state.media[res].url;
    }
    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      playMusic,
      plaMusic,
      initForm,
      ...toRefs(state),
      validateFrom,
      rules,
    };
  },
  data() {
    const generateData = () => {
      const data = []
      for (let i = 1; i <= 15; i++) {
        data.push({
          value: i,
          desc: `Option ${i}`,
        })
      }
      return data
    }
    return {
      data: generateData(),
      value: [],
    }
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}
</style>
