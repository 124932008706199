import request from './index.js'

//获取媒体列表
export function getMedia(data={}) {
  return request({
    url: "/api/media/search",
    method: "GET",
    params: data
  })
}

//获取中继简化列表
export function simpleMedia(data={}) {
  return request({
    url: "/api/media/simple",
    method: "GET",
    params: data
  })
}

//媒体添加
export function addMedia(data={}) {
  return request({
    url: "/api/media/add",
    method: "POST",
    data
  })
}

//媒体上传
export function uploadMedia(data={}) {
  return request({
    url: "/api/media/upload",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data;charset=UTF-8"
    },
    data
  })
}

//媒体删除
export function deleteMedia(data={}) {
  return request({
    url: "/api/media/delete",
    method: "DELETE",
    data
  })
}

//中继批量删除
export function batchdelMedia(data={}) {
  return request({
    url: "/api/media/batchdel",
    method: "DELETE",
    data
  })
}